@import url('../../css/_base.pcss');

@layer base {
    :root {
        --color-primary-lighter: 29, 74%, 59%;
        --color-primary-light: 29, 74%, 49%;
        --color-primary: 29, 74%, 39%;
        --color-primary-dark: 29, 74%, 29%;
        --color-primary-darker: 29, 74%, 19%;

        --color-gradient-start: 29, 74%, 39%;
        --color-gradient-end: 0, 0%, 50%;
    }
}

